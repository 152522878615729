import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DeclineWordPipe } from '@app/shared/pipes/decline-word.pipe';
import { MslCurrencyPipe } from '@app/shared/pipes/msl-currency.pipe';
import { CalendarModule } from '@app/calendar/calendar.module';
import { AlertIconComponent } from '@app/shared/components/alert-icon/alert-icon.component';
import { ButtonsGroupComponent } from '@app/shared/components/buttons-group/buttons-group.component';
import { CloseButtonComponent } from '@app/shared/components/close-button/close-button.component';
import { CustomScrollComponent } from '@app/shared/components/custom-scroll/custom-scroll.component';
import { DrawsButtonsComponent } from '@app/shared/components/draws-buttons/draws-buttons.component';
import { DropDownListComponent } from '@app/shared/components/drop-down-list/drop-down-list.component';
import { HeaderButtonComponent } from '@app/shared/components/header-button/header-button.component';
import { InputBarcodeComponent } from '@app/shared/components/input-barcode/input-barcode.component';
import { MslInputComponent } from '@app/shared/components/msl-input/msl-input.component';
import { OneButtonCustomComponent } from '@app/shared/components/one-button-custom/one-button-custom.component';
import { ParticipantsListComponent } from '@app/shared/components/participants-list/participants-list.component';
import { PrintButtonComponent } from '@app/shared/components/print-button/print-button.component';
import { RegisterBetButtonComponent } from '@app/shared/components/register-bet-button/register-bet-button.component';
import { ResultsNavigationButtonsComponent } from '@app/shared/components/results-navigation-buttons/results-navigation-buttons.component';
import { ResultsNavigatorComponent } from '@app/shared/components/results-navigator/results-navigator.component';
import { WinCategoryTableComponent } from '@app/shared/components/win-category-table/win-category-table.component';
import { MouseTrackDirective } from '@app/shared/directives/mouse-track.directive';
import { MslComponentDirective } from '@app/shared/directives/msl-component.directive';
import { MslInputWithKeyboardComponent } from '@app/shared/components/msl-input-with-keyboard/msl-input-with-keyboard.component';
import { MslInputPinComponent } from '@app/shared/components/msl-input-pin/msl-input-pin.component';
import { MslGameLogoComponent } from '@app/shared/components/msl-game-logo/msl-game-logo.component';
import { MslInputWithEyeComponent } from '@app/shared/components/msl-input-with-eye/msl-input-with-eye.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MaskedInputComponent } from './components/masked-input/masked-input.component';
import { TextMaskModule } from 'angular2-text-mask';
import { FormsModule } from '@angular/forms';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { SpoilerComponent } from './components/spoiler/spoiler.component';
import { LayoverDirective } from './directives/layover.directive';
import { ContentScrolledDirective } from './directives/content-scrolled.directive';
import { Layover2Directive } from '@app/shared/directives/layover2.directive';
import {ButtonsSetComponent} from "@app/shared/components/buttons-set/buttons-set.component";
import {DrawsListComponent} from "@app/shared/components/draws-list/draws-list.component";
import { FiscalReceiptComponent } from './components/fiscal-receipt/fiscal-receipt.component';
import { ExpenditureReceiptComponent } from './components/expenditure-receipt/expenditure-receipt.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';

/**
 * Компоненты, директивы и прочие ресурсы, которые будут экспортированы в другие модули.
 */
const exportComponents = [
	MslInputComponent,
	HeaderButtonComponent,
	DropDownListComponent,
	ButtonsGroupComponent,
	ButtonsSetComponent,
	RegisterBetButtonComponent,
	DrawsButtonsComponent,
	DrawsListComponent,
	InputBarcodeComponent,
	CloseButtonComponent,
	AlertIconComponent,
	PrintButtonComponent,
	WinCategoryTableComponent,
	ParticipantsListComponent,
	ResultsNavigatorComponent,
	ResultsNavigationButtonsComponent,
	CustomScrollComponent,
	OneButtonCustomComponent,
	MslInputWithKeyboardComponent,
	MslInputPinComponent,
	MslGameLogoComponent,
	MslInputWithEyeComponent,
	PhoneNumberComponent,

	MslComponentDirective,
	MouseTrackDirective,

	MslCurrencyPipe,
	DeclineWordPipe
];

/**
 * Дополнительные пайпы для экспорта.
 */
const declarationComponents = [
	MslCurrencyPipe,
	DeclineWordPipe
];

/**
 * Общий модуль, содержащий компоненты, директивы и другие ресурсы,
 * не имеющих зависимостей от других модулей, компонентов и пр.
 */
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule.forChild(),
		CalendarModule.forChild(),
		TextMaskModule,
		FormsModule
	],
	exports: [
		...exportComponents,
		TranslateModule,
		CalendarModule,
		SafeHtmlPipe,
		MaskedInputComponent,
		SwitchButtonComponent,
		SpoilerComponent,
		LayoverDirective,
		Layover2Directive,
		ContentScrolledDirective,
		FiscalReceiptComponent,
		ExpenditureReceiptComponent
	],
	declarations: [
		...exportComponents,
		...declarationComponents,
		SafeHtmlPipe,
		MaskedInputComponent,
		SwitchButtonComponent,
		SpoilerComponent,
		LayoverDirective,
		Layover2Directive,
		ContentScrolledDirective,
  		OnlyNumbersDirective,
		ContentScrolledDirective,
  		FiscalReceiptComponent,
  		ExpenditureReceiptComponent,
    	PhoneNumberComponent
	],
	providers: [
		MslCurrencyPipe,
		DeclineWordPipe
	]
})
export class SharedModule {}
