<!-- КОНТЕЙНЕРЫ (ДИАЛОГИ И КЛАВИАТУРА) -->
<app-dialog-container></app-dialog-container>

<!-- ПРЕЛОАДЕР -->
<div
	class="curtain-container"
	*ngIf="initService.curtainVisibility$$ | async"
	data-cy="curtain-container"
>
	<div class="preloader"
		 data-cy="preloader"
	></div>
	<img class="logo"
		 data-cy="logo"
		 width="141"
		 height="63"
		 src="/assets/img/logo.png"
		 alt="Loading..."
	/>
</div>

<!-- МЕНЮ-ГАМБУРГЕР -->
<app-hamburger-menu
	[currentUserRole]="accessLevel"
	data-cy="hamburger-menu"
>
</app-hamburger-menu>

<div class="page-wrapper"
	 [ngClass]="'page-wrapper_' + pageSuffix"
	 data-cy="page-wrapper"
	 [class.page-wrapper_mob-kb-shown]="appStoreService.mobileKeyboardShown"
>
	<!-- ВЕРХНЯЯ-ПАНЕЛЬ - ЗАГОЛОВОК -->
	<app-top-bar-panel
		class="top-bar-panel"
		data-cy="top-bar-panel"
		[ngClass]="'top-bar-panel_' + pageSuffix"
		[isTerminal]="appStoreService.Settings.appType === AppType.ALTTerminal"
		[style.display]="isLoggedIn ? 'flex': 'none'"
		[loginOperator]="authService.loginOperator_val"
		[communicationReady]="communicationReady"
		[isHomePage]="isHomePage"
		(activateHamburgerMenu)="onActivateHamburgerMenuHandler($event)"
		(clickGoHome)="onClickGoHomeHandler()"
	>
	</app-top-bar-panel>

	<section
		class="page-main"
		[ngClass]="'page-main_' + pageSuffix"
		[class.page-main_central-menu]="isLoggedIn && isVisibleCentralMenu"
		[class.page-main_mob-kb-shown]="appStoreService.mobileKeyboardShown"
		data-cy="page-main"
	>
		<!-- ЦЕНТРАЛЬНОЕ МЕНЮ -->
		<app-central-menu
			class="page-main__central-menu"
			[class.page-main__central-menu_mob-kb-shown]="appStoreService.mobileKeyboardShown"
			[ngClass]="'page-main__central-menu_' + pageSuffix"
			data-cy="page-main-central-menu"
			[style.display]="isLoggedIn && isVisibleCentralMenu ? null : 'none'"
			[currentUserRole]="accessLevel"
		>
		</app-central-menu>

		<router-outlet appMouseTrack [isActive]="false"></router-outlet>
	</section>
</div>
<!--<div id="console" class="console">-->
<!--	<input type="checkbox" class="console__close" id="console_close">-->
<!--	<label for="console_close" class="console__header">Консоль</label>-->
<!--	<div class="console__body">-->
<!--		<ng-container *ngFor="let item of appStoreService.consoleArray">-->
<!--			<div class="console__line" [class.console__line_error]="item.type && item.type === 'error'">{{ item.text }}</div>-->
<!--		</ng-container>-->

<!--	</div>-->
<!--</div>-->

<app-camera id="camera" class="camera"></app-camera>

<div *ngIf="appStoreService.showBrowserPrint" class="tickets-print-container">
	<ng-container *ngIf="appStoreService.tickets.length">
		<div *ngIf="!!appStoreService.tickets[0].url" class="tickets">
			<img *ngFor="let ticket of appStoreService.tickets"
				 class="tickets-print-image"
				 [src]="ticket.imageData"
				 [alt]="ticket.description"
				 (load)="onImageLoaded()"
			/>
		</div>
		<app-fiscal-receipt class="fiscal-receipt"
							[gameName]="appStoreService.printGameName | translate"
							[tickets]="appStoreService.tickets"
		></app-fiscal-receipt>
	</ng-container>

	<app-expenditure-receipt *ngIf="appStoreService.winPaid"
							 [winSum]="appStoreService.winPaid"
	></app-expenditure-receipt>
</div>
