<div class="field">
	<span class="field__prefix">{{ prefix }}</span>
	<input [appOnlyNumbers]
		   class="field__value" #phoneInput
		   pattern="\d*"
		   inputmode="numeric"
		   [attr.minLength]="phoneLength"
		   [attr.maxLength]="phoneLength"
		   [value]="phoneNumber"
		   (input)="onInputChange($event)"
		   (focus)="focused.emit();"
		   (blur)="onTouched()"
	/>
</div>
<div class="errors" *ngIf="invalid && touched">
	<small *ngIf="invalid">Phone number must be 9 digits</small>
</div>
