import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors, Validator
} from "@angular/forms";

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PhoneNumberComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => PhoneNumberComponent),
			multi: true
		}
	]
})
export class PhoneNumberComponent implements ControlValueAccessor, Validator {

	@Input() prefix: string = '+380';

	@Input() phoneLength = 9;

	@Output() focused: EventEmitter<void> = new EventEmitter();

	@ViewChild('phoneInput') phoneInput: ElementRef<HTMLInputElement>;

	phoneNumber: string = '';
	touched = false;
	invalid = false;

	onChange: any = () => {};
	onTouched: any = () => {};

	setFocus(): void {
		this.phoneInput.nativeElement.focus();
	}

	writeValue(value: any): void {
		if (value) {
			if (value.startsWith(this.prefix)) {
				this.phoneNumber = value.substring(this.prefix.length);
			} else {
				this.phoneNumber = value;
			}
		} else {
			this.phoneNumber = '';
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// Optional: Implement if the component should support disabling
	}

	onInputChange(event: Event): void {
		this.phoneNumber = (event.target as HTMLInputElement).value;
		this.invalid = !new RegExp(`^\\d{${this.phoneLength}}$`).test(this.phoneNumber);
		if (!this.invalid) {
			this.onChange(this.prefix + this.phoneNumber);
		} else {
			this.onChange(null);
		}
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return new RegExp(`^\\d{${this.phoneLength}}$`).test(this.phoneNumber) ? null : { invalidPhoneNumber: true };
	}

}
