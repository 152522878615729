import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { alignRight } from '@app/util/utils';
import { LotteryGameCode } from '../configuration/lotteries';
import { UpdateDrawInfoLottery } from '../net/http/api/models/update-draw-info';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { BarcodeObject } from './barcode-object';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { BarcodeGameKey } from '@app/core/configuration/cs';
import { TMLBarcode } from '@app/core/barcode/tml-barcode';
import {
	createUrlParam,
	PARAM_BARCODE, URL_BLANKS,
	URL_CHECK,
	URL_INIT,
	URL_LOTTERIES, URL_OTHER, URL_REPORTING,
	URL_TICKETS,
	URL_TMLBML,
	URL_ZABAVA
} from '@app/util/route-utils';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';

/**
 * Модель с данными анализа введенного баркода.
 */
export interface IBarcodeGameTypeAnalysis {
	/**
	 * Исходный баркод, который был проанализирован.
	 */
	barcode: string;

	/**
	 * Флаг, указывающий, что анализируемый штрих-код соответствует всем шаблонам, описанных в конфигурации.
	 */
	isCorrectBarcode: boolean;

	/**
	 * Регулярное выражение, соответствующее штрих-коду.
	 */
	regExp?: string;

	/**
	 * Определенный код игры.
	 * Определяется явно по конфигурации или по тиражу на терминале. В случае, если код явно не задан и тиражи не будут назначены
	 * на терминал, то в данном случае значение будет undefined.
	 */
	detectedGameCode?: LotteryGameCode;

	/**
	 * Ключ лотереи, которой соответствует штрихкод.
	 * Содержит значение параметра {@link Barcode.game_key game_key}.
	 */
	gameKey?: BarcodeGameKey;

	/**
	 * Модель обнаруженной лотереи по штрих-коду с тиражами и прочими параметрами.
	 */
	detectedLotteryInfo?: UpdateDrawInfoLottery;
}

/**
 * Сервис для работы со сканером штрих-кодов.
 */
@Injectable({
	providedIn: 'root'
})
export class BarcodeReaderService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Флаг, указывающий на активность сервиса.
	 */
	get serviceIsActive(): boolean {
		return this._serviceIsActive;
	}

	/**
	 * Наблюдаемый параметр, который содержит любой новый корректный баркод в системе, считанный из баркодридера.
	 * Эмитируются только валидные баркоды.
	 */
	readonly barcodeSubject$$ = new Subject<IBarcodeGameTypeAnalysis>();

	/**
	 * Наблюдаемый параметр, который содержит штрих-код, считанный с помощью библиотеки Scandit.
	 */
	readonly scanditBarcodeSubject$$ = new Subject<string>();

	/**
	 * Наблюдаемый параметр, который содержит ошибку, возникшую при работе с библиотекой Scandit.
	 */
	readonly scanditErrorSubject$$ = new BehaviorSubject<Error>(null);

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Буфер для хранения последовательности нажатых клавиш.
	 * @private
	 */
	private _keyBuffer = '';

	/**
	 * Флаг, указывающий на активность сервиса сканера штрих-кодов.
	 * @private
	 */
	private _serviceIsActive = false;

	/**
	 * Подписка на событие нажатия клавиш.
	 * @private
	 */
	private _keyboardEventSubscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {AppStoreService} appStoreService Сервис для работы с хранилищем.
	 * @param {Router} router Сервис для работы с маршрутизацией.
	 * @param dialogService Сервис для работы с диалоговыми окнами.
	 */
	constructor(
		private readonly appStoreService: AppStoreService,
		private readonly router: Router,
		private readonly dialogService: DialogContainerService
	) {}

	/**
	 * Запустить детектор баркодов.
	 */
	startDetector(): void {
		Logger.Log.i('BarcodeReaderService', `startDetector -> barcode service is ACTIVE`)
			.console();

		this._keyBuffer = '';
		this._serviceIsActive = true;

		this.unsubscribeFromKeyboardEvent();
		this._keyboardEventSubscription = fromEvent(document, 'keydown')
			.subscribe(this.parseKeyOnSubscription.bind(this));
	}

	/**
	 * Остановить детектор баркодов.
	 */
	stopDetector(): void {
		Logger.Log.i('BarcodeReaderService', `stopDetector -> barcode service is INACTIVE`)
			.console();

		this.unsubscribeFromKeyboardEvent();
		this._serviceIsActive = false;
	}

	/**
	 * Ввести баркод вручную с валидацией по полному циклу.
	 *
	 * @param barcode Строка баркода, введенная вручную.
	 */
	manualBarcodeInput(barcode: string): void {
		Logger.Log.i('BarcodeReaderService', `manualBarcodeInput -> ${barcode}`)
			.console();

		if (!this._serviceIsActive) {
			Logger.Log.i('BarcodeReaderService', `manualBarcodeInput -> barcode reader service isn't active, BC will be skipped`)
				.console();

			return;
		}

		this._keyBuffer = barcode;
		this.barcodeParser();
	}

	/**
	 * Проанализировать штрих-код и создать модель с данными по анализу типа {@link IBarcodeGameTypeAnalysis}
	 * для дальнейшего принятия решения по результатам анализа.
	 *
	 * Для определения названия (кода) лотереи при выплатах по штрихкоду в config.json добавлены параметры:
	 * - game_code
	 * - game_code_cap
	 * - game_key
	 * Описание config.json - {@link https://confluence.emict.net/display/ALT/config.json#config.json-barcodes}
	 * Алгоритм обработки штрихкода для определения лотереи по штрихкоду должен быть таким:
	 * 1) Ищется секция по соответствию штрихкода регулярному выражению
	 * 2.1) В найденной секции анализируется поле game_code. если оно есть, то из него берется код лотереи. Переход к (3)
	 * 2.2) Если в секции есть game_code_cap, то по его значению из штрихкода выбирается подстрока, соответствующая указанной
	 * группе в регулярном выражении. Полученная подстрока будет кодом лотереи. Переход к (3)
	 * 2.3) Если в секции есть game_key, то его значение используется как ключ, идентифицирующий лотерею (группу лотерей). Переход к (3)
	 * 2.4) Если ни одного из 3 указанных полей нет, то лотерея неизвестна. Это НЕ должно вызывать ошибку обработки штрихкода.
	 * 3) Дальнейшая обработка штрихкода
	 *
	 * @param {string} barcode Анализируемый баркод.
	 * @returns {IBarcodeGameTypeAnalysis}
	 */
	determineBarcodeType(barcode: string): IBarcodeGameTypeAnalysis {
		let result = this.createBarcodeGameTypeAnalysis(barcode);
		result = this.parseGameCodeByDraws(result);

		// если не нашли инфу про лотерею, то добавляем ее
		if (!result.detectedLotteryInfo && !!this.appStoreService.Draws) {
			result.detectedLotteryInfo = this.appStoreService.Draws.getLottery(result.detectedGameCode);
		}

		if (result.isCorrectBarcode) {
			const logData = {
				barcode: result.barcode,
				isCorrectBarcode: result.isCorrectBarcode,
				detectedGameCode: result.detectedGameCode,
				regExp: result.regExp,
				gameKey: result.gameKey,
			};
			Logger.Log.i('BarcodeReaderService', `determineBarcodeType -> result data: %s`, logData)
				.console();
		} else {
			Logger.Log.i('BarcodeReaderService', `determineBarcodeType ERROR -> can't recognize barcode [${barcode}]`)
				.console();
		}

		return result;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Создает базовую модель анализа, обработанную по параметрам, описанным в документации
	 * {@link https://confluence.emict.net/display/ALT/config.json#config.json-barcodes}.
	 * Алгоритм анализа описан в {@link determineBarcodeType}.
	 *
	 * @param {string} barcode Анализируемый баркод.
	 * @returns {IBarcodeGameTypeAnalysis}
	 */
	private createBarcodeGameTypeAnalysis(barcode: string): IBarcodeGameTypeAnalysis {
		const result: IBarcodeGameTypeAnalysis = {barcode, isCorrectBarcode: false};
		const step1 = this.appStoreService.Settings.esapBarcodes.find(f => new RegExp(f.regexp).test(barcode));
		if (step1) {
			result.regExp = step1.regexp;
			if (Number.isInteger(step1.game_code)) {
				// шаг 2.1
				result.detectedGameCode = step1.game_code;
				result.isCorrectBarcode = true;
			} else if (Number.isInteger(step1.game_code_cap)) {
				// шаг 2.2
				const group = barcode.match(step1.regexp);
				if (Array.isArray(group) && group.length > step1.game_code_cap) {
					result.detectedGameCode = +group[step1.game_code_cap];
					result.isCorrectBarcode = true;
				}
			} else if (!!step1.game_key) {
				// шаг 2.3
				result.gameKey = step1.game_key as BarcodeGameKey;
				result.isCorrectBarcode = true;
				// switch (step1.game_key) {
				// 	case BarcodeGameKey.LotteryInstantLotoMomentary:
				// 		break;
				// 	case BarcodeGameKey.Unknown:
				// 		break;
				// 	default:
				// 		break;
				// }
			}
		}

		return result;
	}

	/**
	 * Осуществляет поиск данных лотереи по штрихкоду, заполняет свойства
	 * {@link IBarcodeGameTypeAnalysis.detectedLotteryInfo detectedLotteryInfo} и
	 * {@link IBarcodeGameTypeAnalysis.detectedGameCode detectedGameCode} в модели анализа, если
	 * поиск был успешен. В противном случае модель не меняется.
	 *
	 * @param {IBarcodeGameTypeAnalysis} data
	 * @returns {IBarcodeGameTypeAnalysis}
	 */
	private parseGameCodeByDraws(data: IBarcodeGameTypeAnalysis): IBarcodeGameTypeAnalysis {
		const result = {...data};
		const lottery = this.findDrawsForLotteryByBarcode(result.barcode);
		if (lottery) {
			result.detectedLotteryInfo = lottery;

			if (!Number.isInteger(result.detectedGameCode)) {
				result.detectedGameCode = +lottery.lott_code;
			}
		}

		return result;
	}

	/**
	 * Определяет данные {@link UpdateDrawInfoLottery} лотереи с тиражами по заданному баркоду.
	 *
	 * @param {string} barcode Анализируемый баркод.
	 */
	private findDrawsForLotteryByBarcode(barcode: string): UpdateDrawInfoLottery | undefined {
		if (!this.appStoreService || !this.appStoreService.Draws) {
			Logger.Log.i('BarcodeReaderService', `findDrawsForLotteryByBarcode -> can't check draws for entered barcode`)
				.console();

			return undefined;
		}

		// по массиву кодов найти модель лотереи, соответствующей одному из кодов
		const udil = this.appStoreService.Settings.instantLotteryCodes
			.map(code => {
				const li: UpdateDrawInfoLottery = this.appStoreService.Draws.getLottery(code);
				if (li) {
					const tmlBc = new TMLBarcode(barcode);

					if (!!tmlBc && tmlBc.intGameNumber && li.draws.find(f => +f.draw.serie_code === tmlBc.intGameNumber)) {
						return li;
					}
				}

				return undefined;
			})
			.filter(f => !!f);

		return Array.isArray(udil) && udil.length > 0 ? udil[0] : undefined;
	}

	/**
	 * Парсер баркодов.
	 * По введенной комбинации пытается определить тип лотереи. Если введенный штрихкод признается корретным, то функция вернет true
	 * и в наблюдаемый объект {@link barcodeSubject$$} будет эмитирована модель анализа.
	 *
	 * @returns {boolean}
	 */
	barcodeParser(scannedBC?: string): boolean {
		Logger.Log.i('BarcodeReaderService', `barcodeParser -> buffer: (${this._keyBuffer}), length before: (${this._keyBuffer.length})`)
			.console();

		const barcode = scannedBC || this._keyBuffer.trim();
		this._keyBuffer = '';

		const ba = this.determineBarcodeType(barcode);
		if (ba.isCorrectBarcode) {
			Logger.Log.i('BarcodeReaderService', `barcodeParser -> it's correct barcode (${barcode}), will be emitted to other components`)
				.console();

			this.barcodeSubject$$.next(ba);

			this.checkBarcodeForAbilityToRoute(ba);

			return true;
		} else if (/^\d{6}$/.test(barcode)) {
			return true;
		} else if ((/^\d{18}$/.test(barcode)) || (/^\d{8}$/.test(barcode))) {
			this.barcodeSubject$$.next({
				barcode,
				isCorrectBarcode: true
			});
			return true;
		}

		Logger.Log.i('BarcodeReaderService', `barcodeParser -> unknown barcode (${barcode})`)
			.console();

		return ba.isCorrectBarcode;
	}

	/**
	 * Проверка на возможность навигации по определенному маршруту на основе полученного штрих-кода.
	 *
	 * @param {IBarcodeGameTypeAnalysis} barcodeAnalysis
	 */
	private checkBarcodeForAbilityToRoute(barcodeAnalysis: IBarcodeGameTypeAnalysis): void {
		// игнорировать баркодридер для секции ПОКУПКА ТМЛ/БМЛ, ПРОВЕРКА БИЛЕТОВ, СКАНИРОВАНИЕ БЛАНКОВ и ДРУГОЕ
		// в остальных случаях переходим на форму проверки билетов и передаем БЦ как параметр
		if (this.router.url.includes(`${URL_TICKETS}/${URL_CHECK}`)
			|| this.router.url.includes(`${URL_LOTTERIES}/${URL_TMLBML}/${URL_INIT}`)
			|| this.router.url.includes(`${URL_LOTTERIES}/${URL_ZABAVA}/${URL_BLANKS}`)
			|| this.router.url.includes(`${URL_REPORTING}/${URL_OTHER}`)
		) {
			Logger.Log.i('BarcodeReaderService', `checkBarcodeForAbilityToRoute -> skip navigation for current url ${this.router.url}`)
				.console();

			return;
		}

		// при получении корректного штрих-кода ТМЛ/БМЛ перейти на регистрацию билета иначе в проверку
		let path: string;
		path = barcodeAnalysis.detectedGameCode && barcodeAnalysis.detectedGameCode === LotteryGameCode.TML_BML
			&& !this.appStoreService.operator.value.isManager
			? `${URL_LOTTERIES}/${URL_TMLBML}/${URL_INIT}`
			: `${URL_TICKETS}/${URL_CHECK}`;
		if (path) {
			Logger.Log.i('BarcodeReaderService', `checkBarcodeForAbilityToRoute -> will navigate to ${path}`)
				.console();
			this.router.navigate([path], {queryParams: createUrlParam(PARAM_BARCODE, barcodeAnalysis.barcode)})
				.catch(err => {
					Logger.Log.e('BarcodeReaderService', `checkBarcodeForAbilityToRoute -> can't navigate to path: ${err}`)
						.console();
				});
		}
	}

	/**
	 * Отписаться от подписки на событие {@link KeyboardEvent}.
	 */
	private unsubscribeFromKeyboardEvent(): void {
		if (this._keyboardEventSubscription) {
			this._keyboardEventSubscription.unsubscribe();
		}
	}

	// TODO переделать детектор на эту функцию --------------------------------------------
	// private detectBarcode(): Observable<any> {
	// 	const keyDown$: Observable<any> = fromEvent(document, 'keydown');
	// 	keyDown$
	// 		.pipe(
	// 			pluck('keyCode'),
	// 			buffer(keyDown$.pipe(debounceTime(100))),
	// 			filter(keyCodes => {
	// 				return keyCodes.length > 1 && keyCodes[keyCodes.length - 1] === 13;
	// 			})
	// 		);
	//
	// 	return keyDown$;
	// }
	// -------------------------------------------------------------------------------------
	/**
	 * Распознать нажатую клавишу при подписке на события с клавиатуры.
	 * @param event Событие с клавиатуры.
	 * @private
	 */
	private parseKeyOnSubscription(event: KeyboardEvent): void {
		if (!this.dialogService.isPopupOpen) {
			if (Number.isInteger(+event.key)) {
				this._keyBuffer += event.key;
			} else if (event.key === 'Enter') {
				this.barcodeParser();
			}
		}
	}
}

// -----------------------------
//  Static functions
// -----------------------------

/**
 * Рассчитать контрольную сумму баркода.
 *
 * @param {string} barcode Исходный баркод без контрольной суммы.
 * @returns {string} Контрольная сумма в виде строки "XXX".
 */
export const checkSum = (barcode: string): string => {
	const calc = (prevSum: number, value: number, pow: number): number => {
		let result = prevSum + value * Math.pow(2, pow);
		if (result > 999) {
			const str = result.toString();
			result = +str.substr(str.length - 3, 3);
			result += +str.substr(0, str.length - 3);
		}

		return result;
	};

	const arr = Array.from(barcode)
		.map(v => +v);
	const cs = arr
		.reduceRight((sum, value, index) => calc(sum, value, arr.length - index - 1), 0);

	return alignRight(3, `${cs}`, '0');
};

/**
 * Создать объект валидного баркода на основании параметров.
 *
 * @param {number} intGameNumber Номер игры.
 * @param {number} intTicketPackage Номер пачки билетов.
 * @param {number} intTicketNumber Номер билета.
 * @param {[number, number, number]} len Длина номера игры, пачки билетов и номера билета.
 */
export const createBarcodeObject = (
	intGameNumber: number,
	intTicketPackage: number,
	intTicketNumber: number,
	len: [number, number, number] = [4, 6, 3]
): BarcodeObject => {
	const gameNumber = alignRight(len[0], `${intGameNumber}`, '0');
	const ticketPackage = alignRight(len[1], `${intTicketPackage}`, '0');
	const ticketNumber = alignRight(len[2], `${intTicketNumber}`, '0');
	const verificationCode = checkSum(`${gameNumber}${ticketPackage}${ticketNumber}`);
	const barcode = `${gameNumber}${ticketPackage}${ticketNumber}${verificationCode}`;

	return {
		barcode,
		gameNumber,
		intGameNumber,
		ticketPackage,
		intTicketPackage,
		ticketNumber,
		intTicketNumber,
		verificationCode
	};
};
